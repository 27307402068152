// src/modules/order/pages/OrderPlacementPage.js

import React from 'react';
import { Box } from '@mui/material';
import Header from '../../../core/components/Header/Header';
import SideMenu from '../../../core/components/SideMenu/SideMenu';
import OrderPlacement from '../components/OrderPlacement';

const OrderPlacementPage = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Header />
        <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
          <OrderPlacement />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderPlacementPage;