// src/core/utils/api.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);

    const response = await axios.post(`${API_URL}/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.imageUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const checkAdminSession = async () => {
  try {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      throw new Error('No token found');
    }
    const response = await axios.get(`${API_URL}/admin/check-session`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error checking admin session:', error);
    throw error;
  }
};

export const clientRegister = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/clientRegister`, userData);
    return response.data;
  } catch (error) {
    console.error('Error registering client:', error);
    throw error;
  }
};

export const adminRegister = async (adminData) => {
  try {
    const response = await axios.post(`${API_URL}/admin/register`, adminData);
    return response.data;
  } catch (error) {
    console.error('Error registering admin:', error);
    throw error;
  }
};

export const adminLogin = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/admin/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Error logging in admin:', error);
    throw error;
  }
};

export const adminLogout = async () => {
  try {
    const response = await axios.post(`${API_URL}/admin/logout`);
    return response.data;
  } catch (error) {
    console.error('Error logging out admin:', error);
    throw error;
  }
};

export const getAdminUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/admin/users`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching admin users:', error);
    throw error;
  }
};

export const updateAdminUser = async (uid, userData) => {
  try {
    const response = await axios.put(`${API_URL}/admin/users/${uid}`, userData, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating admin user:', error);
    throw error;
  }
};

export const deleteAdminUser = async (uid) => {
  try {
    const response = await axios.delete(`${API_URL}/admin/users/${uid}`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting admin user:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('Usuario no encontrado');
    } else {
      throw new Error('Error al eliminar usuario administrador');
    }
  }
};

export const disableAdminUser = async (uid) => {
  try {
    const response = await axios.put(`${API_URL}/admin/users/${uid}/disable`, {}, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error disabling admin user:', error);
    throw error;
  }
};

export const getRolePermissions = async () => {
  try {
    const response = await axios.get(`${API_URL}/role-permissions`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching role permissions:', error);
    throw error;
  }
};

export const updateRolePermissions = async (permissions) => {
  try {
    const response = await axios.put(`${API_URL}/role-permissions`, permissions, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating role permissions:', error);
    throw error;
  }
};

export const addOrderHistoryEvents = async (orderId, historyEvents) => {
  try {
    const response = await axios.post(`${API_URL}/picking-orders/${orderId}/history`, historyEvents, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding order history events:', error);
    throw error;
  }
};

export const updateFormData = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/form-data/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error('Error updating form data:', error);
    throw error;
  }
};

export const checkIdExists = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/check-id/${id}`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data.exists;
  } catch (error) {
    console.error('Error checking ID:', error);
    throw error;
  }
};

export const addFormData = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/form-data`, formData, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding form data:', error);
    throw error;
  }
};

export const getFormData = async () => {
  try {
    const response = await axios.get(`${API_URL}/form-data`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching form data:', error);
    throw error;
  }
};

// Validate product code and get description
export const validateProduct = async (productCode) => {
  try {
    const response = await axios.get(`${API_URL}/products/${productCode}`);
    return response.data;
  } catch (error) {
    console.error('Error validating product:', error);
    throw error;
  }
};

// Assign product to location
export const assignProductLocation = async (productCode, location, warehouseId) => {
  try {
    const response = await axios.post(`${API_URL}/warehouse/locations`, 
      { productCode, ...location, warehouseId }, 
      {
        headers: {
          Authorization: localStorage.getItem('adminToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error assigning product location:', error);
    throw error;
  }
};

// Search for items by location
export const searchByLocation = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/warehouse/search/location`, {
      params,
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching by location:', error);
    throw error;
  }
};

// Search for location by product code
export const searchByProduct = async (productCode, warehouse) => {
  try {
    const response = await axios.get(`${API_URL}/warehouse/search/product/${productCode}`, {
      params: { warehouse },
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching by product:', error);
    throw error;
  }
};

// Add or update product
export const addOrUpdateProduct = async (productCode, productData) => {
  try {
    const response = await axios.put(`${API_URL}/products/${productCode}`, productData, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding/updating product:', error);
    throw error;
  }
};

// Bulk assign products to locations
export const bulkAssignProductLocations = async (assignments, warehouseId) => {
  try {
    const response = await axios.post(`${API_URL}/warehouse/bulk-assign`, { assignments, warehouseId }, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in bulk location assignment:', error);
    throw error;
  }
};

export const getWarehouses = async () => {
  try {
    const response = await axios.get(`${API_URL}/warehouses`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouses:', error);
    throw error;
  }
};

export const createPickingOrder = async (orderData) => {
  try {
    const response = await axios.post(`${API_URL}/picking-orders`, orderData, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating picking order:', error);
    throw error;
  }
};

export const getPickingOrders = async (warehouseId = null) => {
  try {
    const url = warehouseId 
      ? `${API_URL}/picking-orders?warehouse=${warehouseId}`
      : `${API_URL}/picking-orders`;
    
    const response = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching picking orders:', error);
    throw error;
  }
};

export const getProductLocation = async (productCode, warehouseId) => {
  try {
    const response = await axios.get(`${API_URL}/products/${productCode}/location`, {
      params: { warehouse: warehouseId },
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching product location:', error);
    throw error;
  }
};

export const getStockLevels = async (productCode) => {
  try {
    const response = await axios.get(`${API_URL}/products/${productCode}`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data.stockLevels || {};
  } catch (error) {
    console.error('Error fetching stock levels:', error);
    throw error;
  }
};

export const updatePickingOrder = async (id, orderData) => {
  try {
    const response = await axios.put(`${API_URL}/picking-orders/${id}`, orderData, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating picking order:', error);
    throw error;
  }
};

export const updatePickingOrderStatus = async (orderId, status) => {
  try {
    const response = await axios.put(`${API_URL}/picking-orders/${orderId}/status`, { status }, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating picking order status:', error);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const response = await axios.get(`${API_URL}/products`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const getProduct = async (productCode) => {
  try {
    const response = await axios.get(`${API_URL}/products/${productCode}`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};

export const printPickingOrder = async (orderId) => {
  try {
    const response = await axios.post(`${API_URL}/print-picking-order/${orderId}`, null, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error printing picking order:', error);
    throw error;
  }
};