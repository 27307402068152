// src/modules/warehouse/components/LocationManagement.js

import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Grid, Snackbar, Alert } from '@mui/material';
import { validateProduct, assignProductLocation } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';

const LocationManagement = () => {
    const [alley, setAlley] = useState('');
    const [column, setColumn] = useState('');
    const [row, setRow] = useState('');
    const [productCode, setProductCode] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const { adminUser } = useAdminAuth();
  
    const handleAssignLocation = async () => {
      try {
        await assignProductLocation(productCode, { alley, column, row }, adminUser.warehouse);
        setSnackbar({ open: true, message: 'Ubicación asignada con éxito', severity: 'success' });
        setAlley('');
        setColumn('');
        setRow('');
        setProductCode('');
        setProductDescription('');
      } catch (error) {
        console.error('Error al asignar ubicación:', error);
        setSnackbar({ open: true, message: 'Error al asignar ubicación: ' + error.message, severity: 'error' });
      }
    };
  
    const handleProductCodeChange = async (e) => {
      const code = e.target.value;
      setProductCode(code);
      if (code.length > 5) {
        try {
          const product = await validateProduct(code);
          setProductDescription(product.description);
        } catch (error) {
          setProductDescription('Producto no encontrado');
        }
      } else {
        setProductDescription('');
      }
    };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Pasillo"
            value={alley}
            onChange={(e) => setAlley(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Columna"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Fila"
            value={row}
            onChange={(e) => setRow(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Código de Producto"
            value={productCode}
            onChange={handleProductCodeChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Descripción: {productDescription}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleAssignLocation}>
            Asignar Ubicación
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LocationManagement;