// src/modules/warehouse/components/BulkLocationAssignment.js

import React, { useState, useRef } from 'react';
import { Box, TextField, Button, Typography, Grid, Snackbar, Alert, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { bulkAssignProductLocations, validateProduct } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';

const BulkLocationAssignment = () => {
  const [assignments, setAssignments] = useState([{ productCode: '', alley: '', column: '', row: '', description: '' }]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const inputRefs = useRef([]);
  const { adminUser } = useAdminAuth();

  const handleInputChange = async (index, field, value) => {
    const newAssignments = [...assignments];
    newAssignments[index] = { ...newAssignments[index], [field]: value };

    if (field === 'productCode' && value.length > 5) {
      try {
        const product = await validateProduct(value);
        newAssignments[index].description = product.description || '';
      } catch (error) {
        newAssignments[index].description = 'Product not found';
      }
    }

    setAssignments(newAssignments);
  };

  const handleAddAssignment = () => {
    setAssignments([...assignments, { productCode: '', alley: '', column: '', row: '', description: '' }]);
  };

  const handleCopyPreviousLocation = () => {
    if (assignments.length > 0) {
      const previousAssignment = assignments[assignments.length - 1];
      const newAssignment = {
        productCode: '',
        alley: previousAssignment.alley || '',
        column: previousAssignment.column || '',
        row: previousAssignment.row || '',
        description: ''
      };
      setAssignments([...assignments, newAssignment]);
    }
  };

  const handleRemoveAssignment = (index) => {
    const newAssignments = assignments.filter((_, i) => i !== index);
    setAssignments(newAssignments);
  };

  const handleBulkAssign = async () => {
    try {
      const validAssignments = assignments.filter(a => a.productCode && a.alley && a.column && a.row);
      await bulkAssignProductLocations(validAssignments, adminUser.warehouse);
      setSnackbar({ open: true, message: 'Asignación masiva exitosa', severity: 'success' });
      setAssignments([{ productCode: '', alley: '', column: '', row: '', description: '' }]);
    } catch (error) {
      console.error('Error in bulk assignment:', error);
      setSnackbar({ open: true, message: 'Error en la asignación masiva', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleKeyDown = (event, rowIndex, fieldIndex) => {
    if (event.key === 'Enter' || event.key === 'ArrowDown') {
      event.preventDefault();
      const nextRowIndex = rowIndex + 1;
      if (nextRowIndex < assignments.length) {
        inputRefs.current[nextRowIndex][fieldIndex].focus();
      } else {
        handleAddAssignment();
        setTimeout(() => {
          inputRefs.current[nextRowIndex][fieldIndex].focus();
        }, 0);
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const prevRowIndex = rowIndex - 1;
      if (prevRowIndex >= 0) {
        inputRefs.current[prevRowIndex][fieldIndex].focus();
      }
    } else if (event.key === 'ArrowRight' && event.ctrlKey) {
      event.preventDefault();
      const nextFieldIndex = (fieldIndex + 1) % 4;
      inputRefs.current[rowIndex][nextFieldIndex].focus();
    } else if (event.key === 'ArrowLeft' && event.ctrlKey) {
      event.preventDefault();
      const prevFieldIndex = (fieldIndex - 1 + 4) % 4;
      inputRefs.current[rowIndex][prevFieldIndex].focus();
    }
  };

  return (
    <Box>
      {assignments.map((assignment, rowIndex) => (
        <Grid container spacing={2} key={rowIndex} sx={{ mb: 2 }}>
          {['productCode', 'alley', 'column', 'row'].map((field, fieldIndex) => (
            <Grid item xs={12} sm={2} key={field}>
              <TextField
                fullWidth
                label={field === 'productCode' ? 'Código de Producto' : field === 'alley' ? 'Pasillo' : field === 'column' ? 'Columna' : 'Fila'}
                value={assignment[field]}
                onChange={(e) => handleInputChange(rowIndex, field, e.target.value)}
                inputRef={(el) => {
                  if (!inputRefs.current[rowIndex]) {
                    inputRefs.current[rowIndex] = [];
                  }
                  inputRefs.current[rowIndex][fieldIndex] = el;
                }}
                onKeyDown={(e) => handleKeyDown(e, rowIndex, fieldIndex)}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Descripción"
              value={assignment.description}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <IconButton onClick={() => handleRemoveAssignment(rowIndex)} color="error">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button variant="outlined" onClick={handleAddAssignment} sx={{ mr: 2 }}>
        Agregar Asignación
      </Button>
      <Tooltip title="Copiar ubicación anterior">
        <Button
          variant="outlined"
          onClick={handleCopyPreviousLocation}
          sx={{ mr: 2 }}
          startIcon={<ContentCopyIcon />}
        >
          Copiar Ubicación
        </Button>
      </Tooltip>
      <Button variant="contained" onClick={handleBulkAssign}>
        Asignar Ubicaciones
      </Button>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BulkLocationAssignment;