// src/modules/warehouse/components/ProductManagement.js

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Grid, 
  Snackbar, 
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { addOrUpdateProduct, getProduct, getWarehouses } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';

const ProductManagement = () => {
  const [productCode, setProductCode] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [location, setLocation] = useState({ alley: '', column: '', row: '' });
  const [stockLevels, setStockLevels] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { adminUser } = useAdminAuth();

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = async () => {
    try {
      const warehouseList = await getWarehouses();
      setWarehouses(warehouseList);
      const initialStockLevels = {};
      warehouseList.forEach(warehouse => {
        initialStockLevels[warehouse.id] = 0;
      });
      setStockLevels(initialStockLevels);
    } catch (error) {
      console.error('Error fetching warehouses:', error);
      setSnackbar({ open: true, message: 'Error al cargar almacenes', severity: 'error' });
    }
  };

  const handleFetchProduct = async () => {
    if (!productCode) return;
    try {
      const product = await getProduct(productCode);
      setDescription(product.description);
      setPrice(product.price.toString());
      setLocation(product.location || { alley: '', column: '', row: '' });
      setStockLevels(product.stockLevels || {});
    } catch (error) {
      console.error('Error fetching product:', error);
      setSnackbar({ open: true, message: 'Error al cargar producto', severity: 'error' });
    }
  };

  const handleAddOrUpdateProduct = async () => {
    try {
      const productData = {
        description,
        price: parseFloat(price),
        stockLevels: Object.fromEntries(
          Object.entries(stockLevels).map(([warehouseId, stock]) => [warehouseId, parseInt(stock, 10)])
        ),
      };
  
      await addOrUpdateProduct(productCode, productData);
      setSnackbar({ open: true, message: 'Producto agregado/actualizado con éxito', severity: 'success' });
      // Reset form
      setProductCode('');
      setDescription('');
      setPrice('');
      setLocation({ alley: '', column: '', row: '' });
      setStockLevels({});
    } catch (error) {
      console.error('Error adding/updating product:', error);
      setSnackbar({ open: true, message: 'Error al agregar/actualizar producto', severity: 'error' });
    }
  };

  const handleStockChange = (warehouseId, value) => {
    setStockLevels(prev => ({ ...prev, [warehouseId]: parseInt(value) || 0 }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Código de Producto"
            value={productCode}
            onChange={(e) => setProductCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" onClick={handleFetchProduct}>
            Buscar Producto
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Descripción"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Precio"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Grid>
      </Grid>
      
      {adminUser.role === 'admin' && (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Gestión de Stock
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Almacén</TableCell>
                  <TableCell>Stock</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {warehouses.map((warehouse) => (
                  <TableRow key={warehouse.id}>
                    <TableCell>{warehouse.name}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={stockLevels[warehouse.id] || 0}
                        onChange={(e) => handleStockChange(warehouse.id, e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      
      <Button
        variant="contained"
        onClick={handleAddOrUpdateProduct}
        sx={{ mt: 2 }}
      >
        Guardar Producto
      </Button>
      
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductManagement;