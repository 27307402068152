// src/modules/form/components/FormComponent.js

import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { addFormData } from '../../../core/utils/api';

const FormComponent = ({ onSubmitSuccess, onSubmitError }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    status: '',
    datos: [{ key: 'Dato 1', value: '', isNew: false }],
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDatoChange = (index, field, value) => {
    const newDatos = [...formData.datos];
    newDatos[index] = { ...newDatos[index], [field]: value };
    setFormData({ ...formData, datos: newDatos });
  };

  const addNewDato = () => {
    setFormData({
      ...formData,
      datos: [...formData.datos, { key: `Dato ${formData.datos.length + 1}`, value: '', isNew: true }],
    });
  };

  const deleteDato = (index) => {
    if (formData.datos[index].isNew) {
      const newDatos = formData.datos.filter((_, i) => i !== index);
      setFormData({ ...formData, datos: newDatos });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const uuid = uuidv4();
      const displayId = uuid.slice(-6);
      const dataToSubmit = {
        ...formData,
        id: uuid,
        displayId,
        date: format(new Date(), 'dd/MM/yyyy'),
        datos: formData.datos.map(({ key, value }) => ({ key, value })),
      };
      await addFormData(dataToSubmit);
      onSubmitSuccess(displayId);
      setFormData({
        name: '',
        phone: '',
        status: '',
        datos: [{ key: 'Dato 1', value: '', isNew: false }],
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      onSubmitError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '500px' }}>
      <Typography variant="h4" gutterBottom>
        Formulario
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nombre"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Teléfono"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          margin="normal"
          required
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="status-label">Estado</InputLabel>
          <Select
            labelId="status-label"
            name="status"
            value={formData.status}
            onChange={handleChange}
            label="Estado"
          >
            <MenuItem value="Procesando">Procesando</MenuItem>
            <MenuItem value="Cancelado">Cancelado</MenuItem>
            <MenuItem value="Pendiente">Pendiente</MenuItem>
            <MenuItem value="Entregado">Entregado</MenuItem>
            <MenuItem value="Completado">Completado</MenuItem>
          </Select>
        </FormControl>
        {formData.datos.map((dato, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: 2 }}>
            <TextField
              fullWidth
              label={`Dato ${index + 1}`}
              value={dato.key}
              onChange={(e) => handleDatoChange(index, 'key', e.target.value)}
              required
              sx={{ mr: 1, flex: 1 }}
            />
            <TextField
              fullWidth
              label="Valor"
              value={dato.value}
              onChange={(e) => handleDatoChange(index, 'value', e.target.value)}
              required
              sx={{ mr: 0, flex: 1 }}
            />
            {dato.isNew && (
              <IconButton onClick={() => deleteDato(index)} color="error">
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={addNewDato}
          sx={{ mt: 2, mb: 2 }}
        >
          Agregar Nuevo Dato
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Enviar'}
        </Button>
      </form>
    </Box>
  );
};

export default FormComponent;