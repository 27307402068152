// src/core/pages/UserPage/UserPage.js

import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Alert } from '@mui/material';
import Header from '../../components/Header/Header';
import SideMenu from '../../components/SideMenu/SideMenu';
import UserTable from '../../components/UserTable/UserTable';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import { getAdminUsers, updateAdminUser, deleteAdminUser } from '../../utils/api';
import { useAdminAuth } from '../../context/AdminAuthContext';

const UserPage = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { checkPermission } = useAdminAuth();
  const canViewUsers = checkPermission(1); // 1 is the ID for "Ver página de usuarios"

  useEffect(() => {
    if (canViewUsers) {
      fetchUsers();
    }
  }, [canViewUsers]);

  const fetchUsers = async () => {
    try {
      const userList = await getAdminUsers();
      setUsers(userList);
    } catch (error) {
      console.error('Error fetching users:', error);
      setSnackbar({ open: true, message: 'Error al cargar usuarios', severity: 'error' });
    }
  };

  const handleEdit = async (uid, updatedUser) => {
    try {
      await updateAdminUser(uid, updatedUser);
      fetchUsers();
      setSnackbar({ open: true, message: 'Usuario actualizado exitosamente', severity: 'success' });
    } catch (error) {
      console.error('Error updating user:', error);
      setSnackbar({ open: true, message: 'Error al actualizar usuario', severity: 'error' });
    }
  };

  const handleDelete = async (uid) => {
    try {
      await deleteAdminUser(uid);
      fetchUsers();
      setSnackbar({ open: true, message: 'Usuario eliminado exitosamente', severity: 'success' });
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleToggleEnabled = async (uid, enabled) => {
    try {
      await updateAdminUser(uid, { enabled });
      fetchUsers();
      setSnackbar({ open: true, message: `Usuario ${enabled ? 'habilitado' : 'deshabilitado'} exitosamente`, severity: 'success' });
    } catch (error) {
      console.error('Error toggling user enabled status:', error);
      setSnackbar({ open: true, message: 'Error al cambiar el estado del usuario', severity: 'error' });
    }
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const filteredUsers = users.filter(user => 
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.department.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!canViewUsers) {
    return <AccessDenied />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Header search={searchTerm} onSearchChange={handleSearchChange} />
        <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
          <UserTable 
            users={filteredUsers} 
            onEdit={handleEdit}
            onDelete={handleDelete}
            onToggleEnabled={handleToggleEnabled}
          />
        </Box>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserPage;