// src/modules/placeholderTable/components/PlaceholderTable.js

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Chip,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PrintIcon from '@mui/icons-material/Print';
import { printPickingOrder } from '../../../core/utils/api';
import { format } from 'date-fns';

const PlaceholderTable = ({ data, onOpenDetails, sortOrder, onSortOrderChange }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Pendiente':
        return { backgroundColor: 'orange', color: 'white' };
      case 'En Progreso':
        return { backgroundColor: 'blue', color: 'white' };
      case 'Completado':
        return { backgroundColor: 'green', color: 'white' };
      case 'Cancelado':
        return { backgroundColor: '#757575', color: 'white' };
      default:
        return {};
    }
  };

  const handlePrint = async (id) => {
    try {
      await printPickingOrder(id);
      // You might want to add a snackbar or some other notification here
      console.log('Picking order printed successfully');
    } catch (error) {
      console.error('Error printing picking order:', error);
      // You might want to add an error notification here
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="picking orders table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Creado Por</TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Fecha
                <IconButton onClick={onSortOrderChange} size="small">
                  {sortOrder === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>Estado</TableCell>
            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell component="th" scope="row">
                {item.displayId}
              </TableCell>
              <TableCell>{item.createdBy}</TableCell>
              <TableCell>{format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}</TableCell>
              <TableCell>
                <Chip
                  label={item.status}
                  sx={getStatusStyle(item.status)}
                />
              </TableCell>
              <TableCell align="center">
                <Button 
                  variant="contained" 
                  sx={{ 
                    backgroundColor: '#d60f16',
                    '&:hover': {
                      backgroundColor: '#b60d13',
                    },
                    mr: 1
                  }} 
                  onClick={() => onOpenDetails(item)}
                >
                  Detalles
                </Button>
                <Tooltip title="Imprimir">
                  <IconButton 
                    onClick={() => handlePrint(item.id)}
                    color="default"
                  >
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlaceholderTable;