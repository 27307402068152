// src/modules/warehouse/pages/WarehousePage.js

import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import Header from '../../../core/components/Header/Header';
import SideMenu from '../../../core/components/SideMenu/SideMenu';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';
import AccessDenied from '../../../core/components/AccessDenied/AccessDenied';
import LocationManagement from '../components/LocationManagement';
import WarehouseSearch from '../components/WarehouseSearch';
import ProductManagement from '../components/ProductManagement';
import BulkLocationAssignment from '../components/BulkLocationAssignment';

const WarehousePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const { checkPermission } = useAdminAuth();
  const canViewWarehouse = checkPermission(9); // Assuming 9 is the ID for "Ver página de almacén"

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!canViewWarehouse) {
    return <AccessDenied />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Header search={searchTerm} onSearchChange={handleSearchChange} />
        <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
          <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Asignar Ubicación" />
            <Tab label="Asignación Masiva" />
            <Tab label="Buscar en Almacén" />
            <Tab label="Gestión de Productos" />
          </Tabs>
          {tabValue === 0 && <LocationManagement />}
          {tabValue === 1 && <BulkLocationAssignment />}
          {tabValue === 2 && <WarehouseSearch />}
          {tabValue === 3 && <ProductManagement />}
        </Box>
      </Box>
    </Box>
  );
};

export default WarehousePage;