// src/modules/dashboard/components/DashboardComponent.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getPickingOrders } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';
import { format, parseISO, isValid } from 'date-fns';

const DashboardComponent = () => {
  const [pickingOrders, setPickingOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { adminUser } = useAdminAuth();

  useEffect(() => {
    fetchPickingOrders();
  }, [adminUser]);

  const fetchPickingOrders = async () => {
    try {
      const warehouseId = adminUser.role === 'admin' ? null : adminUser.warehouse;
      const data = await getPickingOrders(warehouseId);
      console.log('Fetched picking orders:', data);  // Add this line for debugging
      setPickingOrders(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching picking orders:', error);
      setLoading(false);
    }
  };

  const getStatusCounts = () => {
    const allStatuses = ['Pendiente', 'En Progreso', 'Completado', 'Cancelado'];
    const counts = pickingOrders.reduce((acc, item) => {
      if (item.status) {
        acc[item.status] = (acc[item.status] || 0) + 1;
      }
      return acc;
    }, {});
    return allStatuses.map(status => ({ status, count: counts[status] || 0 }));
  };

  const getTotalOrders = () => pickingOrders.length;

  const getLatestOrder = () => {
    if (pickingOrders.length === 0) return null;
    return pickingOrders.reduce((latest, current) => {
      const currentDate = current.createdAt ? new Date(current.createdAt) : null;
      const latestDate = latest.createdAt ? new Date(latest.createdAt) : null;
      if (!currentDate) return latest;
      if (!latestDate) return current;
      return currentDate > latestDate ? current : latest;
    });
  };

  const getOrdersByDate = () => {
    const counts = pickingOrders.reduce((acc, item) => {
      if (item.createdAt) {
        const date = new Date(item.createdAt);
        if (isValid(date)) {
          const formattedDate = format(date, 'yyyy-MM-dd');
          acc[formattedDate] = (acc[formattedDate] || 0) + 1;
        }
      }
      return acc;
    }, {});
    return Object.entries(counts)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const statusCounts = getStatusCounts();

  return (
    <Box sx={{ flexGrow: 1, mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
            <Typography variant="h6" gutterBottom>
              Total de Órdenes
            </Typography>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {getTotalOrders()}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
            <Typography variant="h6" gutterBottom>
              Última Orden
            </Typography>
            <Typography variant="body1" component="div" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {getLatestOrder() ? (
                <>
                  <span>ID: {getLatestOrder().displayId}</span>
                  <span>Fecha: {getLatestOrder().createdAt ? format(new Date(getLatestOrder().createdAt), 'dd/MM/yyyy HH:mm') : 'Fecha no disponible'}</span>
                </>
              ) : (
                'No hay órdenes'
              )}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
            <Typography variant="h6" gutterBottom>
              Estado más común
            </Typography>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {statusCounts.reduce((a, b) => a.count > b.count ? a : b).status}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {statusCounts.map((status) => (
              <Grid item xs={12} sm={6} md={3} key={status.status}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 100 }}>
                  <Typography variant="h6" gutterBottom>
                    {status.status}
                  </Typography>
                  <Typography variant="h4" component="div">
                    {status.count}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 300, pb: 6 }}>
            <Typography variant="h6" gutterBottom>
              Órdenes por Estado
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={statusCounts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" dy={5} />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Bar dataKey="count" fill="#b60d13" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 300, pb: 6 }}>
            <Typography variant="h6" gutterBottom>
              Órdenes por Fecha
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={getOrdersByDate()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" dy={5} />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Line type="monotone" dataKey="count" stroke="#b60d13" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardComponent;