// src/core/components/Admin/RolesComponent.js

import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { getRolePermissions, updateRolePermissions } from '../../utils/api';

const functionalities = [
  { id: 1, name: 'Ver página de usuarios' },
  { id: 2, name: 'Ver página de formulario' },
  { id: 3, name: 'Ver página de tabla' },
  { id: 4, name: 'Editar entrada (General)' },
  { id: 5, name: 'Editar entrada (Detalles)' },
  { id: 6, name: 'Editar entrada (Datos General)' },
  { id: 7, name: 'Editar entrada (Datos Agregar Fila)' },
  { id: 8, name: 'Editar entrada (Datos Eliminar Fila)' },
  { id: 9, name: 'Editar almacen (General)' },
  { id: 10, name: 'Hacer picking (General)' },
];

const roles = ['tienda', 'almacen', 'supervisor', 'admin'];

const RolesComponent = () => {
  const [permissions, setPermissions] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = async () => {
    try {
      const data = await getRolePermissions();
      setPermissions(data);
    } catch (error) {
      console.error('Error fetching permissions:', error);
      setSnackbar({ open: true, message: 'Error al cargar los permisos', severity: 'error' });
    }
  };

  const handleToggle = async (functionalityId, role) => {
    try {
      const updatedPermissions = {
        ...permissions,
        [role]: {
          ...permissions[role],
          [functionalityId]: !permissions[role][functionalityId],
        },
      };
      await updateRolePermissions(updatedPermissions);
      setPermissions(updatedPermissions);
      setSnackbar({ open: true, message: 'Permisos actualizados correctamente', severity: 'success' });
    } catch (error) {
      console.error('Error updating permissions:', error);
      setSnackbar({ open: true, message: 'Error al actualizar los permisos', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ p: 2 }}>Permisos de Roles</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Funcionalidad</TableCell>
              {roles.map((role) => (
                <TableCell key={role} align="center">{role}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {functionalities.map((functionality) => (
              <TableRow key={functionality.id}>
                <TableCell>{functionality.name}</TableCell>
                {roles.map((role) => (
                  <TableCell key={role} align="center">
                    <Switch
                      checked={permissions[role]?.[functionality.id] || false}
                      onChange={() => handleToggle(functionality.id, role)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RolesComponent;