// src/modules/order/components/OrderPlacement.js

import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, Snackbar, Alert, Autocomplete, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { createPickingOrder, getStockLevels, getProducts, getWarehouses, getProductLocation } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';

const OrderPlacement = () => {
  const [products, setProducts] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [orderItems, setOrderItems] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { adminUser } = useAdminAuth();
  const [productLocation, setProductLocation] = useState(null);

  useEffect(() => {
    fetchProducts();
    fetchWarehouses();
  }, []);

  const fetchProducts = async () => {
    try {
      const productList = await getProducts();
      setProducts(productList);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchWarehouses = async () => {
    try {
      const warehouseList = await getWarehouses();
      setWarehouses(warehouseList);
    } catch (error) {
      console.error('Error fetching warehouses:', error);
    }
  };

  const handleAddItem = async () => {
    if (selectedProduct && quantity > 0) {
      try {
        const location = await getProductLocation(selectedProduct.code, adminUser.warehouse);
        if (!location) {
          setSnackbar({
            open: true,
            message: 'Error: El producto no tiene una ubicación asignada en este almacén.',
            severity: 'error',
          });
          return;
        }
        setOrderItems([...orderItems, { ...selectedProduct, quantity, location }]);
        setSelectedProduct(null);
        setQuantity(1);
      } catch (error) {
        console.error('Error checking product location:', error);
        setSnackbar({
          open: true,
          message: 'Error al verificar la ubicación del producto',
          severity: 'error',
        });
      }
    }
  };

  const handleRemoveItem = (index) => {
    const newOrderItems = orderItems.filter((_, i) => i !== index);
    setOrderItems(newOrderItems);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const newOrderItems = [...orderItems];
    newOrderItems[index].quantity = newQuantity;
    setOrderItems(newOrderItems);
  };

  const handleSubmit = async () => {
    try {
      if (orderItems.length === 0) {
        setSnackbar({ open: true, message: 'Agregue al menos un producto', severity: 'error' });
        return;
      }
      if (!adminUser.warehouse) {
        setSnackbar({ open: true, message: 'Error: Usuario no tiene almacén asignado', severity: 'error' });
        return;
      }
      await createPickingOrder({ 
        items: orderItems.map(item => ({ productCode: item.code, quantity: item.quantity })), 
        storeId: adminUser.warehouse,
        createdBy: adminUser.name
      });
      setSnackbar({ open: true, message: 'Orden de picking creada exitosamente', severity: 'success' });
      setOrderItems([]);
    } catch (error) {
      console.error('Error creating picking order:', error);
      setSnackbar({ open: true, message: 'Error al crear la orden de picking', severity: 'error' });
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={products}
            getOptionLabel={(option) => `${option.code} - ${option.description}`}
            renderInput={(params) => <TextField {...params} label="Seleccionar Producto" />}
            value={selectedProduct}
            onChange={(_, newValue) => setSelectedProduct(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            type="number"
            label="Cantidad"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" onClick={handleAddItem} disabled={!selectedProduct}>
            Agregar Producto
          </Button>
        </Grid>
      </Grid>

      {selectedProduct && (
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                {warehouses.map((warehouse) => (
                  <TableCell key={warehouse.id}>{warehouse.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{selectedProduct.code} - {selectedProduct.description}</TableCell>
                {warehouses.map((warehouse) => (
                  <TableCell key={warehouse.id}>
                    {selectedProduct.stockLevels?.[warehouse.id] || 0}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemoveItem(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" onClick={handleSubmit} disabled={orderItems.length === 0}>
        Crear Orden
      </Button>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OrderPlacement;