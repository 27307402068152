// src/modules/placeholderTable/components/PlaceholderDetailsModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';
import { updatePickingOrder, addOrderHistoryEvents, printPickingOrder, getProduct } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';
import ConfirmDialog from '../../../core/components/ConfirmDialog/ConfirmDialog';

const PlaceholderDetailsModal = ({ open, handleClose, selectedItem, onUpdateSuccess }) => {
  const [editableItem, setEditableItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [pendingHistoryEvents, setPendingHistoryEvents] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { adminUser } = useAdminAuth();

  useEffect(() => {
    if (selectedItem) {
      fetchItemDetails();
    }
  }, [selectedItem]);

  const fetchItemDetails = async () => {
    try {
      setEditableItem(selectedItem);
    } catch (error) {
      console.error('Error fetching item details:', error);
      setSnackbar({ open: true, message: 'Error al cargar detalles del pedido', severity: 'error' });
    }
  };

  const addPendingHistoryEvent = (event) => {
    setPendingHistoryEvents(prev => {
      const newEvent = {
        date: new Date().toISOString(),
        event: event,
        userName: adminUser ? adminUser.name : 'Sistema'
      };
      return [...prev, newEvent];
    });
    setHasUnsavedChanges(true);
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setEditableItem(prevItem => ({
      ...prevItem,
      status: newStatus
    }));
    addPendingHistoryEvent(`Estado cambiado a ${newStatus}`);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    setConfirmDialogOpen(true);
    setConfirmDialogAction('save');
  };

  const handleConfirmSave = async () => {
    setConfirmDialogOpen(false);
    try {
      const updatedItem = {
        ...editableItem,
        userName: adminUser ? adminUser.name : 'Sistema'
      };

      await updatePickingOrder(updatedItem.id, updatedItem);

      if (pendingHistoryEvents.length > 0) {
        await addOrderHistoryEvents(updatedItem.id, pendingHistoryEvents);
        
        setEditableItem(prevItem => ({
          ...prevItem,
          historial: [...(prevItem.historial || []), ...pendingHistoryEvents]
        }));

        setPendingHistoryEvents([]);
      }

      onUpdateSuccess(updatedItem);
      setIsEditing(false);
      setHasUnsavedChanges(false);
      handleClose();
      setSnackbar({ open: true, message: 'Orden de picking actualizada exitosamente', severity: 'success' });
    } catch (error) {
      console.error('Error updating picking order:', error);
      setSnackbar({ open: true, message: 'Error al actualizar la orden de picking', severity: 'error' });
    }
  };

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      setConfirmDialogOpen(true);
      setConfirmDialogAction('close');
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
    setHasUnsavedChanges(false);
    handleClose();
  };

  const handlePrint = async () => {
    try {
      await printPickingOrder(editableItem.id);
      setSnackbar({ open: true, message: 'Orden de picking impresa exitosamente', severity: 'success' });
    } catch (error) {
      console.error('Error printing picking order:', error);
      setSnackbar({ open: true, message: 'Error al imprimir la orden de picking', severity: 'error' });
    }
  };

  if (!editableItem) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" fontWeight="bold">
              Detalles de la Orden de Picking
            </Typography>
            <IconButton onClick={handleCloseModal} sx={{ minWidth: 'auto', p: 1, '&:hover': { backgroundColor: '#b60d1308' } }}>
              <ClearIcon sx={{ color: '#d60f16' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            ID: {editableItem.displayId}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Creado por: {editableItem.createdBy}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Fecha de creación: {new Date(editableItem.createdAt).toLocaleString()}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Estado
            </Typography>
            <Select
              fullWidth
              value={editableItem.status}
              onChange={handleStatusChange}
              disabled={!isEditing}
            >
              <MenuItem value="Pendiente">Pendiente</MenuItem>
              <MenuItem value="En Progreso">En Progreso</MenuItem>
              <MenuItem value="Completado">Completado</MenuItem>
              <MenuItem value="Cancelado">Cancelado</MenuItem>
            </Select>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Productos
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descripción</TableCell>
                    <TableCell>Cantidad</TableCell>
                    <TableCell>Ubicación</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editableItem.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.productCode}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {item.location && item.location.alley && item.location.column && item.location.row
                          ? `${item.location.alley}-${item.location.column}-${item.location.row}`
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Historial
            </Typography>
            <List>
              {[...(editableItem?.historial || []), ...pendingHistoryEvents].map((entry, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <HistoryIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={entry.event}
                    secondary={`${new Date(entry.date).toLocaleString()} - ${entry.userName}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
          {!isEditing ? (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#d60f16',
                  '&:hover': {
                    backgroundColor: '#b60d13',
                  },
                  mr: 1
                }}
                onClick={toggleEditMode}
              >
                Editar
              </Button>
              <Button
                variant="contained"
                onClick={handlePrint}
              >
                Imprimir
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  borderColor: '#d60f16',
                  color: '#d60f16',
                  '&:hover': {
                    borderColor: '#b60d13',
                    backgroundColor: 'rgba(214, 15, 22, 0.04)',
                  },
                  mr: 1
                }}
                onClick={toggleEditMode}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#d60f16',
                  '&:hover': {
                    backgroundColor: '#b60d13',
                  },
                }}
                onClick={handleSave}
              >
                Guardar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={confirmDialogOpen}
        title={confirmDialogAction === 'save' ? "Confirmar Guardar" : "Confirmar Cerrar"}
        content={
          confirmDialogAction === 'save'
            ? "¿Está seguro de que desea guardar los cambios?"
            : "Hay cambios sin guardar. ¿Está seguro de que desea cerrar sin guardar?"
        }
        onConfirm={confirmDialogAction === 'save' ? handleConfirmSave : handleConfirmClose}
        onCancel={() => setConfirmDialogOpen(false)}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PlaceholderDetailsModal;