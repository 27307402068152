// src/modules/warehouse/components/WarehouseSearch.js

import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { searchByLocation, searchByProduct } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';

const WarehouseSearch = () => {
  const [searchType, setSearchType] = useState('location');
  const [alley, setAlley] = useState('');
  const [column, setColumn] = useState('');
  const [row, setRow] = useState('');
  const [productCode, setProductCode] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { adminUser } = useAdminAuth();

  const handleSearch = async () => {
    try {
      let results;
      if (searchType === 'location') {
        results = await searchByLocation({ 
          alley: alley || undefined, 
          column: column || undefined, 
          row: row || undefined, 
          warehouse: adminUser.warehouse 
        });
      } else {
        results = await searchByProduct(productCode, adminUser.warehouse);
      }
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching:', error);
      setSearchResults([]);
      // You might want to add a snackbar or some other notification here
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button 
            variant={searchType === 'location' ? 'contained' : 'outlined'} 
            onClick={() => setSearchType('location')}
          >
            Buscar por Ubicación
          </Button>
          <Button 
            variant={searchType === 'product' ? 'contained' : 'outlined'} 
            onClick={() => setSearchType('product')}
            sx={{ ml: 2 }}
          >
            Buscar por Producto
          </Button>
        </Grid>
        {searchType === 'location' ? (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Pasillo"
                value={alley}
                onChange={(e) => setAlley(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Columna"
                value={column}
                onChange={(e) => setColumn(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Fila"
                value={row}
                onChange={(e) => setRow(e.target.value)}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Código de Producto"
              value={productCode}
              onChange={(e) => setProductCode(e.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSearch}>
            Buscar
          </Button>
        </Grid>
      </Grid>
      {searchResults.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código de Producto</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Pasillo</TableCell>
                <TableCell>Columna</TableCell>
                <TableCell>Fila</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((result) => (
                <TableRow key={result.productCode}>
                  <TableCell>{result.productCode}</TableCell>
                  <TableCell>{result.description}</TableCell>
                  <TableCell>{result.alley}</TableCell>
                  <TableCell>{result.column}</TableCell>
                  <TableCell>{result.row}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default WarehouseSearch;