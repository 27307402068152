// src/core/config/menuItems.js

import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import TableChart from '@mui/icons-material/TableChart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const menuItems = [
  { text: 'Inicio', icon: HomeIcon, path: '/' },
  // { text: 'Formulario', icon: AssignmentIcon, path: '/form' },
  { text: 'Crear Orden', icon: ShoppingCartIcon, path: '/order-placement' },
  { text: 'Lista de Picking', icon: ListAltIcon, path: '/picking-list' },
  { text: 'Almacén', icon: WarehouseIcon, path: '/warehouse' },
  { text: 'Usuarios', icon: PeopleIcon, path: '/users' },
  // { text: 'Lista de Picking', icon: ListAltIcon, path: '/picking-list' },
];